import { Link, Outlet } from "react-router";
import { HeartCrackIcon } from "lucide-react";
import { Button } from "~/components/ui/button";

import { ROUTES } from "~/services/const";
import { isRouteErrorResponse, useRouteError } from "react-router";
import { logger } from "~/services/logger";
import SupportEmail from "~/components/SupportEmail";

export default function AuthLayout() {
  return (
    <div className="flex-1 flex flex-col pb-20 sm:pb-40 pt-12 sm:pt-24 bg-gradient-to-tr from-red-900 to-red-700">
      <Outlet />
    </div>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    logger.error("Route error response in AuthLayout", {
      status: error.status,
      statusText: error.statusText,
      data: error.data,
    });
  } else if (error instanceof Error) {
    logger.error("Error in AuthLayout", {
      message: error.message,
      stack: error.stack,
      name: error.name,
    });
  } else {
    // For other types of errors (like strings)
    logger.error("Unknown error in AuthLayout", {
      error: String(error),
    });
  }

  return (
    <div className="container max-w-2xl mx-auto bg-white px-4 py-4 mt-20 mb-20 flex-1 text-center">
      <div className="max-w-xl min-h-60 mx-auto bg-gray-100 p-4 flex-1 rounded border flex items-center justify-center flex-col gap-4 text-gray-700">
        <h1 className="font-bold text-2xl text-gray-500 mt-6 mb-4">
          <HeartCrackIcon size={128} strokeWidth={1.5} className="text-gray-400" />
        </h1>
        <p>Lamentamos mas ocorreu um erro.</p>
        <p>
          Tenta novamente e se o erro continuar, por favor, contacta-nos através do email <SupportEmail />.
        </p>
        <div className="flex flex-col gap-4 mt-6">
          <Button asChild>
            <Link to={ROUTES.DASHBOARD}>Ver as minhas peças</Link>
          </Button>

          <Button variant="link" onClick={() => window.location.reload()}>
            Tentar novamente
          </Button>
        </div>
      </div>
    </div>
  );
}
